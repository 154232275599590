/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import GoogleAnalytics from './google-analytics';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <svg display="none" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         width="224" height="32" viewBox="0 0 224 32">
        <defs>
            <g id="icon-user">
                <path className="path1"
                      d="M19.463 21.96c-0.691-0.11-0.707-2.010-0.707-2.010s2.030-2.010 2.473-4.713c1.19 0 1.926-2.873 0.735-3.884 0.050-1.064 1.53-8.354-5.964-8.354s-6.014 7.29-5.965 8.354c-1.19 1.011-0.455 3.884 0.735 3.884 0.442 2.703 2.473 4.713 2.473 4.713s-0.016 1.9-0.707 2.010c-2.226 0.354-10.537 4.020-10.537 8.040h28c0-4.020-8.311-7.685-10.537-8.040z"></path>
            </g>
            <g id="icon-twitter">
                <path className="path1"
                      d="M30.72 6.869c-1.085 0.482-2.248 0.805-3.47 0.952 1.248-0.747 2.206-1.931 2.656-3.341-1.166 0.691-2.459 1.195-3.834 1.466-1.102-1.174-2.672-1.907-4.41-1.907-3.336 0-6.040 2.704-6.040 6.038 0 0.474 0.053 0.934 0.157 1.376-5.019-0.251-9.47-2.656-12.448-6.31-0.522 0.891-0.821 1.93-0.821 3.037 0 2.096 1.067 3.944 2.688 5.027-0.99-0.032-1.922-0.302-2.736-0.755 0 0.026 0 0.050 0 0.075 0 2.926 2.083 5.366 4.845 5.923-0.506 0.138-1.040 0.211-1.592 0.211-0.389 0-0.766-0.037-1.136-0.107 0.768 2.4 3 4.146 5.642 4.194-2.067 1.621-4.67 2.586-7.501 2.586-0.488 0-0.968-0.029-1.44-0.085 2.672 1.714 5.846 2.714 9.259 2.714 11.109 0 17.184-9.203 17.184-17.186 0-0.261-0.005-0.522-0.018-0.781 1.181-0.851 2.203-1.915 3.014-3.126z"></path>
            </g>
            <g id="icon-rss">
                <path className="path1"
                      d="M3.203 4.478v3.789c11.338 0 20.528 9.192 20.528 20.53h3.79c0-13.43-10.888-24.318-24.318-24.318zM3.203 12.058v3.79c7.152 0 12.95 5.797 12.95 12.949h3.787c0-9.243-7.493-16.739-16.738-16.739zM6.835 21.533c-2.005 0-3.632 1.627-3.632 3.634s1.627 3.632 3.632 3.632c2.006 0 3.634-1.626 3.634-3.632s-1.627-3.634-3.634-3.634z"></path>
            </g>
            <g id="icon-heart">
                <path className="path1"
                      d="M32 11.192c0 2.699-1.163 5.126-3.015 6.808h0.015l-10 10c-1 1-2 2-3 2s-2-1-3-2l-9.985-10c-1.852-1.682-3.015-4.109-3.015-6.808 0-5.077 4.116-9.192 9.192-9.192 2.699 0 5.126 1.163 6.808 3.015 1.682-1.852 4.109-3.015 6.808-3.015 5.077 0 9.192 4.116 9.192 9.192z"></path>
            </g>
            <g id="icon-archive">
                <path className="path1"
                      d="M27.149 8c0-1.6-1.592-1.6-1.592-1.6h-19.114c0 0-1.592 0-1.592 1.6v1.6h22.298v-1.6zM22.37 3.2h-12.741c0 0-1.592 0-1.592 1.6h15.926c0-1.6-1.594-1.6-1.594-1.6zM30.333 9.6c-0.942-0.947-0.942-0.947-0.942-0.947v2.547h-26.782v-2.547c0 0 0 0-0.942 0.947s-1.618 1.202-1.237 3.202c0.376 1.992 2.205 12.92 2.475 14.398 0.299 1.622 1.947 1.6 1.947 1.6h22.298c0 0 1.648 0.022 1.947-1.6 0.272-1.478 2.099-12.406 2.477-14.398 0.379-2-0.296-2.254-1.24-3.202zM22.37 18.24c0 0 0 1.6-1.594 1.6h-9.554c-1.592 0-1.592-1.6-1.592-1.6v-3.2h2.23v2.56h8.277v-2.56h2.234v3.2z"></path>
            </g>
            <g id="icon-arrow-left">
                <path className="path1"
                      d="M18.299 7.226c-0.669 0.653-7.203 7.514-7.203 7.514-0.357 0.349-0.534 0.805-0.534 1.261s0.178 0.912 0.534 1.261c0 0 6.534 6.861 7.203 7.512 0.669 0.653 1.872 0.698 2.586 0 0.712-0.696 0.768-1.667-0.002-2.52l-5.995-6.253 5.995-6.253c0.77-0.854 0.714-1.826 0.002-2.522-0.715-0.696-1.918-0.653-2.586 0z"></path>
            </g>
            <g id="icon-arrow-right">
                <path className="path1"
                      d="M13.701 7.226c0.669 0.653 7.203 7.514 7.203 7.514 0.358 0.349 0.536 0.805 0.536 1.261s-0.178 0.912-0.536 1.261c0 0-6.534 6.861-7.203 7.512-0.669 0.653-1.87 0.698-2.586 0-0.714-0.696-0.77-1.667 0-2.52l5.997-6.253-5.995-6.253c-0.77-0.854-0.714-1.826 0-2.522s1.917-0.653 2.584 0z"></path>
            </g>
        </defs>
      </svg>
      <div className="container">
        <Header siteTitle={data.site.siteMetadata.title} />
      </div>
      <hr className="main"/>
      <div className="container">
        <div className="content">{children}</div>
        <footer className="clearfix">
            Made with{' '}
            <svg className="icon icon-heart" viewBox="0 0 32 32" title="Love!">
                <use xlinkHref="#icon-heart"></use>
            </svg>{' '}
            in SPb
        </footer>
      </div>
      <GoogleAnalytics />
    </>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
