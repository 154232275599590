import React from "react"

function GoogleAnalytics() {

    return (
        <>
          <div dangerouslySetInnerHTML={{__html: `<script type="text/javascript">
    var _gaq=[['_setAccount','UA-40309450-1'],['_trackPageview']];
    (function(d,t){var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
    g.src=('https:'==location.protocol?'//ssl':'//www')+'.google-analytics.com/ga.js';
    s.parentNode.insertBefore(g,s)}(document,'script'));
</script>`}}/>
        </>
    )
}

GoogleAnalytics.defaultProps = {
};

GoogleAnalytics.propTypes = {
};

export default GoogleAnalytics
