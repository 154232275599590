import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header className="main">
      <h1><Link to="/">rinat.io</Link></h1>
      <ul className="text-nowrap">
          <li>
              <Link to="/about" title="About Me">
                  <svg className="icon icon-user" viewBox="0 0 32 32">
                      <use xlinkHref="#icon-user"></use>
                  </svg>
              </Link>
          </li>{' '}
          <li>
              <Link to="/blog" title="Blog Archive">
                  <svg className="icon icon-archive" viewBox="0 0 32 32">
                      <use xlinkHref="#icon-archive"></use>
                  </svg>
              </Link>
          </li>{' '}
          <li>
              <a href="https://twitter.com/rinat_io" title="Twitter">
                  <svg className="icon icon-twitter" viewBox="0 0 32 32">
                      <use xlinkHref="#icon-twitter"></use>
                  </svg>
              </a>
          </li>{' '}
          <li>
              <a href="http://feeds.feedburner.com/rinatio" title="Subscribe" rel="alternate"
                 type="application/rss+xml">
                  <svg className="icon icon-rss" viewBox="0 0 32 32">
                      <use xlinkHref="#icon-rss"></use>
                  </svg>
              </a>
          </li>
      </ul>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header
